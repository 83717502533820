import isNumber from 'lodash/isNumber';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

export const InputController = ({ fieldName, label, isEditingMode, disable, format, ...props }) => {
  const { watch, control } = useFormContext();
  const watchFieldName = watch(fieldName);

  const formattedValue = useMemo(() => {
    // we can't check just for isNumber(watchFieldName) because:
    // - watchFieldName will always be a string.
    // - +watchFieldName will be NaN if the string is not a number.
    // - isNumber(NaN) = true.
    // so one day we'll need a function to replace that shit below:
    return (watchFieldName || watchFieldName === 0) && isNumber(+watchFieldName) ? format(+watchFieldName) : '';
  }, [watchFieldName, format]);

  const handleBlur = (onBlur, onChange) => {
    if (isEditingMode && (watchFieldName || watchFieldName === 0) && isNumber(+watchFieldName)) {
      onChange(+watchFieldName);
    }

    onBlur();
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, onBlur } }) => (
        <Form.Group className="flex items-center justify-center">
          {label && <Form.Label className="m-0 text-muted">{label}:</Form.Label>}
          <Form.Control
            /* since this is actually a number input, but we want to show the formatted value, we need to use a text input */
            /* so for editing mode we set the type to number, and for viewing mode we set the type to text */
            /* then we use the formatted value for viewing mode, and the actual value for editing mode */
            disabled={disable}
            type={isEditingMode ? 'number' : 'text'}
            value={isEditingMode ? watchFieldName : formattedValue}
            onChange={(event) => onChange(event.target.value)}
            onBlur={() => handleBlur(onBlur, onChange)}
            autoFocus
            {...props}
            dir="ltr"
            style={{ textAlign: 'right' }}
          />
        </Form.Group>
      )}
    />
  );
};
