import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';

export const PieChart = ({ queryKey, queryFn, prepareDataCb = () => {}, options = {} }) => {
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
  });

  const chartData = useMemo(() => {
    if (!data) {
      return null;
    }

    return prepareDataCb(data);
  }, [data, prepareDataCb]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!chartData) {
    return null;
  }

  return <Pie data={chartData} options={options} height={'200px'} width={'200px'} />;
};
