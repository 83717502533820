import { Col, Row } from 'react-bootstrap';

import Box from 'components/UI/Box';

import {
  AuctionTypeFilter,
  CovenantFilter,
  IssuerFilter,
  LeadUnderwriterFilter,
  LinkageFilter,
  MaxDurationFilter,
  MaxRatingFilter,
  MinDurationFilter,
  MinRatingFilter,
  MosadiAuctionDateFilter,
  OfferingTypeFilter,
  SectorFilter,
} from './filters';

export const PublicOfferingsFilters = (props) => {
  return (
    <Box {...props}>
      <Row>
        <Col md={2}>
          <MosadiAuctionDateFilter />
        </Col>
        <Col md={2}>
          <CovenantFilter />
        </Col>
        <Col md={2}>
          <SectorFilter />
        </Col>
        <Col md={2}>
          <OfferingTypeFilter />
        </Col>
        <Col md={2}>
          <AuctionTypeFilter />
        </Col>
        <Col md={2}>
          <LinkageFilter />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={2}>
          <MinDurationFilter />
        </Col>
        <Col md={2}>
          <MaxDurationFilter />
        </Col>
        <Col md={2}>
          <MinRatingFilter />
        </Col>
        <Col md={2}>
          <MaxRatingFilter />
        </Col>
        <Col md={2}>
          <IssuerFilter />
        </Col>
        <Col md={2}>
          <LeadUnderwriterFilter />
        </Col>
      </Row>
    </Box>
  );
};
