import { Row, Spinner } from 'react-bootstrap';

import { ScorecardItem } from './ScorecardItem';
import { ScorecardPanel } from './ScorecardPanel';

const ScorecardItemsList = ({ items }) => {
  return (
    <Row>
      {items.map((item, index) => (
        <ScorecardItem key={index} label={item.label} value={item.value} />
      ))}
    </Row>
  );
};

const Loader = () => {
  return (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" />
    </div>
  );
};

export const Scorecard = ({ items, isLoading }) => {
  return <ScorecardPanel>{isLoading ? <Loader /> : <ScorecardItemsList items={items} />}</ScorecardPanel>;
};
