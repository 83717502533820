import uniq from 'lodash/uniq';

import { columnNames as dividedAccountsCols } from 'shared/const/dividedAccountsTable';
import { calcBidValue, unHashBankAccount } from 'shared/utils/offers';

const HIGH_AMOUNT = 50_000_000;
const LOW_AMOUNT = 800_000;

const getBids = (offer, bids) => {
  const { type, unitStructures } = offer?.initiation.auctionDetails;

  return bids.map((bid) => {
    bid.value = calcBidValue(bid, unitStructures, type);
    return bid;
  });
};

export const getLowValueBids = (offer, bids) => {
  const bidsValues = getBids(offer, bids);

  const lowValueBids = bidsValues.filter((bid) => bid.value < LOW_AMOUNT);
  return lowValueBids;
};

export const getHighValueBids = (offer, bids) => {
  const bidsValues = getBids(offer, bids);

  const highValueBids = bidsValues.filter((bid) => bid.value > HIGH_AMOUNT);
  return highValueBids;
};

export const getUniqLimitsForAuctionByCompany = (existingLimits, newBids) => {
  const newLimits = newBids.map((bid) => bid.limit);
  const allLimits = [...newLimits, ...existingLimits];
  const distinctLimits = uniq(allLimits);
  return distinctLimits;
};

export const prepareUnitedAccountsForMutation = (offer, data) => {
  const selectedCashAccount = unHashBankAccount(data.cashAccount);
  const selectedSharesAccount = unHashBankAccount(data.sharesAccount);
  const bids = [0, 1, 2]
    .map((index) => {
      const prefix = `bid_${index}_`;
      const amount = data[prefix + 'amount'];
      const limit = data[prefix + 'limit'];
      if (amount && (limit || limit === 0)) {
        return { amount, limit };
      }
      return null;
    })
    .filter((bid) => !!bid);
  const expectedPayload = {
    offerId: offer._id,
    unitedAccounts: {
      selectedCashAccount,
      selectedSharesAccount,
    },
    bids,
  };

  return expectedPayload;
};

const buildDividedCashAccount = (item) => {
  return {
    bank: item[dividedAccountsCols.cash_account_bank],
    branchNumber: +item[dividedAccountsCols.cash_account_branch] || undefined,
    accountNumber: item[dividedAccountsCols.cash_account_number] ? String(item[dividedAccountsCols.cash_account_number]) : undefined,
  };
};

const buildDividedSharesAccount = (item) => {
  return {
    bank: item[dividedAccountsCols.shares_account_bank],
    branchNumber: +item[dividedAccountsCols.shares_account_branch] || undefined,
    accountNumber: item[dividedAccountsCols.shares_account_number] ? String(item[dividedAccountsCols.shares_account_number]) : undefined,
  };
};

const buildFormattedTableRow = (row) => {
  const result = {
    accountName: row[dividedAccountsCols.account_name],
    selectedCashAccount: buildDividedCashAccount(row),
    selectedSharesAccount: buildDividedSharesAccount(row),
    amount: row[dividedAccountsCols.amount],
    limit: row[dividedAccountsCols.limit],
    maxPercentageFromAuction: row[dividedAccountsCols.maxPercentageFromAuction],
  };

  return result;
};

export const prepareDividedAccountsForMutation = (offer, data) => {
  const { table } = data;

  const expectedPayload = {
    offerId: offer._id,
    table: table.map(buildFormattedTableRow),
  };
  return expectedPayload;
};

const getAmountByLimit = (table, limit) => {
  return table.filter((item) => item.limit === limit).reduce((totalAmount, row) => totalAmount + row.amount, 0);
};

export const buildDividedAccountsBidTableFromPayload = (payload) => {
  const { table } = payload;
  const distinctLimits = [...new Set(table.map((item) => item.limit))];

  const bids = distinctLimits.map((limit) => {
    const amount = getAmountByLimit(table, limit);
    return { amount, limit };
  });

  return bids;
};
