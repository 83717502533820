import { useQuery } from '@tanstack/react-query';
import numbro from 'numbro';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsFiltersContext } from 'context/PublicOfferingsFilters';

import { getPublicOfferingsGeneralStats } from 'shared/apis';

import { Scorecard } from 'components/UI/Scorecard/Scorecard';

export const PublicOfferingsScorecard = () => {
  const { filters } = useContext(PublicOfferingsFiltersContext);
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ['publicOfferingsStats', JSON.stringify(filters)],
    queryFn: () => getPublicOfferingsGeneralStats(filters),
  });

  const [dataForDisplay, setDataForDisplay] = useState(null);

  useEffect(() => {
    if (!data) {
      setDataForDisplay({
        totalOfferings: 0,
        sumPublicClosingIls: 0,
        avgInstDemandIls: 0,
        avgInstClosingMargin: 0,
        avgInstClosingYield: 0,
        avgDistributionFee: 0,
      });
    } else {
      setDataForDisplay(data);
    }
  }, [data]);

  const scorecardItems = useMemo(() => {
    return [
      {
        label: t('components.publicOfferingsScorecard.totalOfferings'),
        value: dataForDisplay?.totalOfferings,
      },
      {
        label: t('components.publicOfferingsScorecard.totalRaised'),
        value: numbro(dataForDisplay?.sumPublicClosingIls).format({
          output: 'currency',
          mantissa: 1,
          optionalMantissa: true,
          currencyPosition: 'postfix',
          spaceSeparatedCurrency: true,
          average: true,
        }),
      },
      {
        label: t('components.publicOfferingsScorecard.avgDemand'),
        value: numbro(dataForDisplay?.avgInstDemandIls).format({
          output: 'currency',
          mantissa: 1,
          optionalMantissa: true,
          currencyPosition: 'postfix',
          spaceSeparatedCurrency: true,
          average: true,
        }),
      },
      {
        label: t('components.publicOfferingsScorecard.avgMargin'),
        value: numbro(dataForDisplay?.avgInstClosingMargin).formatCurrency({
          currencyPosition: 'postfix',
          currencySymbol: '%',
          mantissa: 2,
        }),
      },
      {
        label: t('components.publicOfferingsScorecard.avgYield'),
        value: numbro(dataForDisplay?.avgInstClosingYield).formatCurrency({
          currencyPosition: 'postfix',
          currencySymbol: '%',
          mantissa: 2,
        }),
      },
      {
        label: t('components.publicOfferingsScorecard.avgDistributionFee'),
        value: numbro(dataForDisplay?.avgDistributionFee).formatCurrency({
          currencyPosition: 'postfix',
          currencySymbol: '%',
          mantissa: 2,
        }),
      },
    ];
  }, [dataForDisplay, t]);

  return <Scorecard items={scorecardItems} isLoading={isLoading} />;
};
