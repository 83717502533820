import 'chart.js/auto';
import numbro from 'numbro';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsFiltersContext } from 'context/PublicOfferingsFilters';

import { getPublicOfferingsGroupByFieldSum } from 'shared/apis';

import { SERIES_COLOR_SCHEMA } from 'components/UI/Charts/consts';
import { PieChart } from 'components/UI/Charts/Pie';

export const PublicOfferingsCommonPieChart = ({ groupByKey, title }) => {
  const { t } = useTranslation();
  const { filters } = useContext(PublicOfferingsFiltersContext);

  const prepareData = useCallback((data) => {
    return {
      labels: data?.map((item) => item.name),
      datasets: [
        {
          data: data?.map((item) => item.sum),
          backgroundColor: SERIES_COLOR_SCHEMA.slice(0, data?.length || 0),
          metadata: data?.map((item) => item.count),
        },
      ],
    };
  }, []);

  const buildTooltipLabel = useCallback(
    (context) => {
      const label = context.label || '';
      const value = context.raw;
      const formattedValue = numbro(value).format('0.0a$');
      const count = context.dataset.metadata[context.dataIndex];
      return `${label} - ${formattedValue} (${count} ${t('common.offerings')})`;
    },
    [t],
  );

  return (
    <div>
      <h2 className="text-center">{title}</h2>
      <PieChart
        queryKey={[`public offerings ${groupByKey} pie`, JSON.stringify(filters)]}
        queryFn={() => getPublicOfferingsGroupByFieldSum(groupByKey, filters)}
        prepareDataCb={prepareData}
        options={{
          plugins: {
            legend: {
              position: 'left',
            },
            tooltip: {
              callbacks: {
                label: buildTooltipLabel,
              },
            },
          },
        }}
      />
    </div>
  );
};
