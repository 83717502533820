import compact from 'lodash/compact';
import numbro from 'numbro';

// ensure empty values are not displayed
const wrapper = (value, formatCb) => {
  return value ? formatCb(value) : '';
};

export const columns = compact([
  {
    Header: 'underwriter',
    accessor: 'name',
  },
  {
    Header: 'count',
    accessor: 'count',
  },
  {
    Header: 'sum',
    accessor: 'sum',
    Cell: (row) => wrapper(row.sum, (value) => numbro(value).format('0.0a$')),
  },
]);
