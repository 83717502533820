import { useQuery } from '@tanstack/react-query';
import numbro from 'numbro';
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { getYieldPerLimit } from 'shared/apis';

import { useIsBidLimitValid } from 'hooks/offers/useIsBidLimitValid';

export const YieldPerLimit = ({ index }) => {
  const { watch } = useFormContext();
  const { offer } = useContext(OfferCreationContext);
  const auctionId = offer.initiation.auctionDetails.auctionId;
  const { t } = useTranslation();

  const fieldName = `bid_${index}_limit`;
  const { isLimitValid } = useIsBidLimitValid(fieldName);
  const limit = +watch(fieldName);

  const { data, isLoading } = useQuery({
    queryKey: ['yieldPerLimit', index, limit, auctionId],
    queryFn: async () => {
      const res = await getYieldPerLimit(auctionId, limit);
      return res[0];
    },
    enabled: !!limit && isLimitValid,
  });

  const cellValue = useMemo(() => {
    if (!limit) {
      return '-';
    }

    if (isLoading) {
      return t('common.loading');
    }

    if (!data && !isLimitValid) {
      return 'X';
    }

    if (!data) {
      return t('common.error');
    }

    return `${numbro(data.effectiveYield * 100).format('0.00')}% (${Math.floor(data.gap * 100 * 100)})`;
  }, [data, isLoading, isLimitValid, limit, t]);

  return <td style={{ backgroundColor: '#fefce8', color: '#f97316' }}>{cellValue}</td>;
};
