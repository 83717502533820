import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import * as xlsx from 'node-xlsx';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';

import { sortDirections } from 'shared/const/sortDirections';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useDebounce } from 'hooks/useDebounce';

import { PaginationWrapper, StyledTable } from 'components/UI/TableQuery/styles';

import Paginations from '../PaginationControl';
import { ScrollableTable } from '../ScrollableTable';

import { ColumnSortSpan } from './ColumnSortSpan';
import { LimitedHeightTable } from './LimitedHeightTable';
import { getSortKey } from './utils';

export const TableQuery = ({
  tableName,
  Columns,
  columnTPrefix,
  defaultSort,
  queryKey,
  queryFn,
  filters = {},
  getErrorAlertContent = () => null,
  selected,
  setSelected,
  actions,
  style,
  withSelection,
  withSearch = true,
  withPagination = true,
  withSort = true,
  withExport = false,
  exportSuccessCallback = () => null,
  enabled = true,
  setQueryData,
  defaultLimit = 1000,
  scrollable = false,
  limitedHeight = false,
  maxHeight = '80vh',
}) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(withPagination ? 50 : defaultLimit); // arbitrary number, just to make sure we get all the data
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(defaultSort);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { addAlert } = useContext(AlertContentContext);
  const searchKey = useDebounce(searchQuery, 500);

  useEffect(() => {
    setPage(1);
  }, [searchKey]);

  const onQueryError = useCallback(
    (error) => {
      let errorAlertContent = getErrorAlertContent(error);
      if (!errorAlertContent) {
        errorAlertContent = errorAlertBuilder.bug(error);
      }
      addAlert(errorAlertContent);
    },
    [addAlert, getErrorAlertContent],
  );

  const {
    isLoading,
    isRefetching,
    data: paginatedData,
    error: paginatedDataError,
  } = useQuery({
    queryKey: [queryKey, filters, searchKey, page, limit, sortBy],
    queryFn: () => queryFn({ searchKey, page, limit, sortBy, filters }),
    placeholderData: keepPreviousData,
    enabled,
    retry: false,
  });

  useEffect(() => {
    /**
     * sometimes we want access to the data outside of the component, so we pass a setter function to setQueryData.
     * reason we don't just use the data from the useQuery is because we don't have easy access to the queryKey.
     */
    if (setQueryData) {
      setQueryData(paginatedData?.data);
    }
  }, [paginatedData, setQueryData]);

  /* the data supposed to be fetched on every 'download' click, and the useQuery state isn't used, but we do not use 'useMutation' because it is a get request */
  const {
    refetch,
    data: fullData,
    error: fullDataError,
  } = useQuery({
    enabled: false,
    queryKey: [`${queryKey}_full`, filters, searchKey, 0, 1000, sortBy],
    queryFn: () => queryFn({ searchKey, page: 0, limit: 500, sortBy, filters }),
  });

  useEffect(() => {
    if (fullData) {
      const { data } = fullData;
      if (isEmpty(data)) return;

      // data json to csv
      const csvData = [];
      const columnsForExport = Columns.filter((column) => !column.noExport);

      // add headers
      const headers = columnsForExport.map((column) => column.Header);
      csvData.push(headers);

      // add data
      data.forEach((row) => {
        const rowData = [];
        columnsForExport.forEach((column) => {
          let value;

          if (column.exportAccessor) {
            if (isFunction(column.exportAccessor)) {
              value = column.exportAccessor(row);
            } else {
              value = get(row, column.exportAccessor);
            }
          } else {
            value = get(row, column.accessor);
          }

          rowData.push(value);
        });
        csvData.push(rowData);
      });

      const buffer = xlsx.build([{ name: queryKey, data: csvData }]);

      // download using file-saver
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${tableName || queryKey}.xlsx`);

      // callback to be invoked after the export is done
      if (isFunction(exportSuccessCallback)) {
        exportSuccessCallback();
      }
    }
  });

  /* alert about errors, separated into two useEffects because we do now want to add two error alerts whenever one of the errors variables are changed */
  useEffect(() => {
    if (paginatedDataError) {
      onQueryError(paginatedDataError);
    }
  }, [paginatedDataError, onQueryError]);

  useEffect(() => {
    if (fullDataError) {
      onQueryError(fullDataError);
    }
  }, [fullDataError, onQueryError]);

  useEffect(() => {
    if (withSelection && selected?.length !== paginatedData?.data?.length) {
      setSelectAll(false);
    }
  }, [selected, paginatedData, withSelection]);

  if (isLoading || isRefetching) {
    return (
      <Container className="d-flex justify-center align-items-center">
        <Spinner animation="border" size="xl" />
      </Container>
    );
  }

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = (column) => {
    if (!withSort || column.noSort) return;

    const sortKey = getSortKey(column);
    let sortDirection;
    if (sortBy[sortKey] && sortBy[sortKey] === sortDirections.DESC) {
      sortDirection = sortDirections.ASC;
    } else {
      // default sort direction
      sortDirection = sortDirections.DESC;
    }

    setSortBy({
      [sortKey]: sortDirection,
    });
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    setSelected(checked ? paginatedData?.data.map((row) => row._id) : []);
  };

  const handleSelect = (row) => {
    const isSelected = selected.includes(row._id);

    let newSelected = [];
    if (!isSelected) {
      newSelected = [...selected, row._id];
      setSelected(newSelected);
    } else {
      newSelected = selected.filter((id) => id !== row._id);
      setSelected(newSelected);
    }

    const isAllSelected = paginatedData?.data.length === newSelected.length;
    setSelectAll(isAllSelected);
  };

  const handleSetLimit = (e) => {
    setLimit(parseInt(e.target.value));
    setPage(1);
  };

  const getColumnLength = () => {
    let length = Columns.length;
    if (withSelection) length += 1;
    return length;
  };

  const renderBody = () => {
    if (isEmpty(paginatedData?.data)) {
      return (
        <tr>
          <td colSpan={getColumnLength()} className="text-center">
            {t('components.TableQuery.noData')}
          </td>
        </tr>
      );
    }

    /* generate single row */
    return paginatedData?.data.map((row, index) => (
      <tr key={index}>
        {withSelection && (
          <td>
            <input type="checkbox" onChange={() => handleSelect(row)} name={row._id} checked={selectAll || selected.includes(row._id)} />
          </td>
        )}
        {Columns.map((column, index) => {
          return generateSingleCell(column, row, index);
        })}
      </tr>
    ));
  };

  const handleExport = () => {
    refetch();
  };

  let TableComp = StyledTable;
  if (scrollable) {
    TableComp = ScrollableTable;
  } else if (limitedHeight) {
    TableComp = LimitedHeightTable;
  }

  return (
    <Container>
      <div className="d-flex justify-content-between flex-row-reverse mb-3">
        {withSearch && (
          <div className="d-flex flex-row">
            <Form.Control onChange={handleQueryChange} placeholder={t('components.TableQuery.controls.globalFilterPlaceHolder')} />
            {withExport && (
              <Button onClick={() => handleExport()} variant="light">
                <FontAwesomeIcon icon={faFileExcel} />
              </Button>
            )}
          </div>
        )}

        {actions && (
          <div className="d-flex gap-2">
            {actions.map((action, index) => {
              if (action.type === 'button') {
                return (
                  <Button key={index} variant={action.variant} onClick={() => action.onClick(paginatedData?.data)} disabled={action.disabled}>
                    {action.label}
                  </Button>
                );
              } else return null;
            })}
          </div>
        )}
      </div>
      <TableComp responsive borderless hover maxHeight={maxHeight} style={{ ...style }}>
        <thead>
          <tr>
            {withSelection && (
              <th>
                <input type="checkbox" onChange={handleSelectAll} checked={selectAll || selected.length === paginatedData?.data?.length} />
              </th>
            )}
            {Columns.map((column, index) => (
              <th onClick={() => handleSort(column)} style={{ cursor: 'pointer', ...column.thStyle }} key={index}>
                {column.Header && columnTPrefix ? t(columnTPrefix + column.Header) : column.Header}
                {withSort && <ColumnSortSpan column={column} sortBy={sortBy} />}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </TableComp>

      {withPagination && (
        <PaginationWrapper>
          <div className="flex items-center gap-2">
            <label className="m-0  text-txLabel fw-bold whitespace-nowrap form-label">{t('components.TableQuery.controls.limit')}: </label>
            <Form.Select value={limit} onChange={handleSetLimit} aria-label="Default select example">
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
            </Form.Select>
          </div>
          {!isLoading && paginatedData?.count > limit && <Paginations page={page} count={paginatedData?.count} setPage={setPage} limit={limit} />}
        </PaginationWrapper>
      )}
    </Container>
  );
};

const generateSingleCell = (column, row, index) => {
  const { tdStyle, Cell, accessor } = column;
  const cellContent = isFunction(Cell) ? Cell(row) : get(row, accessor);
  const cellStyle = generateCellStyle(tdStyle, row, accessor);
  const result = (
    <td key={index} style={cellStyle}>
      {cellContent}
    </td>
  );
  return result;
};

const generateCellStyle = (tdStyle, row, accessor) => {
  if (!tdStyle) return { verticalAlign: 'middle' };
  if (isFunction(tdStyle)) return tdStyle(row[accessor]);
  return tdStyle;
};

TableQuery.propTypes = {
  columnTPrefix: PropTypes.string,
  defaultSort: PropTypes.object,
  queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filters: PropTypes.object,
  queryFn: PropTypes.func.isRequired,
  withSelection: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['button', 'link']),
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  Columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      cell: PropTypes.func,
      tdStyle: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      noSort: PropTypes.bool,
      thStyle: PropTypes.object,
    }),
  ).isRequired,
  style: PropTypes.object,
  withSearch: PropTypes.bool,
  withPagination: PropTypes.bool,
  withSort: PropTypes.bool,
  withExport: PropTypes.bool,
  exportSuccessCallback: PropTypes.func,
  enabled: PropTypes.bool,
  setQueryData: PropTypes.func,
  defaultLimit: PropTypes.number,
  scrollable: PropTypes.bool,
  limitedHeight: PropTypes.bool,
  tableName: PropTypes.string,
  getErrorAlertContent: PropTypes.func,
};
