import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsFiltersContext, PublicOfferingsFiltersContextProvider } from 'context/PublicOfferingsFilters';

import { PublicOfferingsFilters } from 'components/publicOfferingsFilters';
import {
  PublicOfferingsLinkagePieChart,
  PublicOfferingsOfferingTypePieChart,
  PublicOfferingsSectorPieChart,
} from 'components/PublicOfferingsPieCharts';
import { PublicOfferingsScorecard } from 'components/PublicOfferingsScorecard/PublicOfferingsScorecard';
import { PublicOfferingsTable } from 'components/PublicOfferingsTable';
import { PublicOfferingsUnderwriterTable } from 'components/PublicOfferingsUnderwriterTable';
import Box from 'components/UI/Box';

export const PublicOfferingsScreen = () => {
  const { t } = useTranslation();

  return (
    <PublicOfferingsFiltersContextProvider>
      <Container className="mt-5 mb-5">
        <h1 className="text-center">{t('screens.publicOfferings.texts.title')}</h1>
        <PublicOfferingsFilters className="mt-5" />
        <Box className="mt-5">
          <PublicOfferingsScorecard />
        </Box>
        <Box className="mt-5">
          <PublicOfferingsTableWrapper />
        </Box>
        <section className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
          <Box>
            <PublicOfferingsSectorPieChart />
          </Box>
          <Box>
            <PublicOfferingsLinkagePieChart />
          </Box>
          <Box>
            <PublicOfferingsOfferingTypePieChart />
          </Box>
          <Box>
            <PublicOfferingsUnderwriterTable maxHeight="500px" />
          </Box>
        </section>
      </Container>
    </PublicOfferingsFiltersContextProvider>
  );
};

const PublicOfferingsTableWrapper = (props) => {
  const { filters } = useContext(PublicOfferingsFiltersContext);

  return <PublicOfferingsTable scrollable withPagination filters={filters} {...props} />;
};
