import React, { useCallback, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AlertContentContext } from 'context/Alert';

import { ALERT_TYPES_VARIANTS_MAP } from 'shared/const/alerts';

import './style.scss';

const buildModalClassName = (alertAlertContent) => {
  const alertAlertType = alertAlertContent?.type;
  const variant = ALERT_TYPES_VARIANTS_MAP[alertAlertType] || ALERT_TYPES_VARIANTS_MAP.default;
  return `alert-modal alert-modal-${variant}`;
};

const getModalVariant = (alertAlertContent) => {
  const alertAlertType = alertAlertContent?.type;
  return ALERT_TYPES_VARIANTS_MAP[alertAlertType] || ALERT_TYPES_VARIANTS_MAP.default;
};

const getTransactionId = (activeAlertContent) => {
  return activeAlertContent?.error?.response?.data?.error?.transactionId;
};

const AlertModal = () => {
  const { activeAlertContent, removeActiveAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();

  const translateConditionally = useCallback(
    (pathOrText) => {
      return activeAlertContent.needTranslate ? t(pathOrText) : pathOrText;
    },
    [t, activeAlertContent],
  );

  const transactionId = getTransactionId(activeAlertContent);
  const modalClassName = buildModalClassName(activeAlertContent);
  const variant = getModalVariant(activeAlertContent);
  return (
    <Modal show={activeAlertContent} onHide={removeActiveAlert} centered className={modalClassName}>
      <Modal.Header>
        <Modal.Title>{translateConditionally(activeAlertContent.title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={`alert-text-${variant}`}>{translateConditionally(activeAlertContent.description)}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex-grow-1 flex justify-between align-items-center">
          {transactionId && <div className="text-xs text-gray-500">{transactionId}</div>}
          <Button variant={variant} onClick={removeActiveAlert}>
            {t('components.errorModal.buttons.close')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
