import { useContext } from 'react';

import { AlertContentContext } from 'context/Alert';

import { getMosadiAcceptanceMessageDocumentPDF, getOfferProposalDocumentPDF } from 'shared/apis';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

export const useOpenPdfFactory = () => {
  const { addAlert } = useContext(AlertContentContext);

  const openDocumentAsPdf = (document) => {
    if (!document) {
      addAlert(errorAlertBuilder.bug());
      return;
    }

    const byteCharacters = atob(document);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create Object URL and open it
    const objectURL = URL.createObjectURL(blob);
    window.open(objectURL, '_blank');
  };

  const openOfferProposalDocumentAsPdf = async (offer) => {
    const document = await getOfferProposalDocumentPDF(offer._id);
    const { encodedPdf } = document;
    openDocumentAsPdf(encodedPdf);
  };

  const openMosadiAcceptanceMessageDocumentAsPdf = async (offer) => {
    const document = await getMosadiAcceptanceMessageDocumentPDF(offer._id);
    openDocumentAsPdf(document);
  };

  return { openOfferProposalDocumentAsPdf, openMosadiAcceptanceMessageDocumentAsPdf };
};
