import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PublicOfferingsFiltersContext } from 'context/PublicOfferingsFilters';

import { getPublicOfferingsGroupByFieldSum } from 'shared/apis';
import { sortDirections } from 'shared/const/sortDirections';

import { PUBLIC_OFFERINGS_GROUP_BY_FIELDS } from 'components/PublicOfferingsPieCharts/consts';
import { TableQuery } from 'components/UI/TableQuery';

import { columns } from './coulmns';

export const PublicOfferingsUnderwriterTable = (props) => {
  const { t } = useTranslation();
  const { filters } = useContext(PublicOfferingsFiltersContext);

  const queryFn = useCallback(
    async ({ sortBy }) => {
      const data = await getPublicOfferingsGroupByFieldSum(PUBLIC_OFFERINGS_GROUP_BY_FIELDS.UNDERWRITER, filters, sortBy);

      return { data };
    },
    [filters],
  );

  return (
    <div>
      <h2 className="text-center">{t('components.publicOfferingsUnderwriterTable.title')}</h2>
      <TableQuery
        queryKey={['public-offerings-underwriter-table', JSON.stringify(filters)]}
        queryFn={queryFn}
        defaultSort={{ sum: sortDirections.DESC }}
        Columns={columns}
        columnTPrefix="components.publicOfferingsUnderwriterTable.columns."
        enabled
        withPagination={false}
        withSearch={false}
        scrollable
        {...props}
      />
    </div>
  );
};
