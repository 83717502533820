import { useTranslation } from 'react-i18next';

import { PUBLIC_OFFERINGS_GROUP_BY_FIELDS } from '../consts';
import { PublicOfferingsCommonPieChart } from '../PublicOfferingsCommonPieChart';

export const PublicOfferingsOfferingTypePieChart = () => {
  const { t } = useTranslation();
  const groupByKey = PUBLIC_OFFERINGS_GROUP_BY_FIELDS.OFFERING_TYPE;

  return <PublicOfferingsCommonPieChart groupByKey={groupByKey} title={t('components.publicOfferingsOfferingTypePieChart.title')} />;
};
